import '../styles/spinner.scss';

function Spinner() {
    return (
        <div className='spinner-container'>
            <div className='spinner' />
        </div>
    );
}

export default Spinner;
